import React from 'react'
import  '../ComponensCSS/footerStyles.css'
import logo from '../Image/logo.png'
import { BsFacebook, BsInstagram, BsTwitter } from "react-icons/bs"

export default function Footer() {
    return (
        <div className='footerBackground'>
            <div className='container p-5'>
                <div className='row'>

                    <img className='col-4 d-flex align-items-center' src={logo} />

                    <div className='col-4 d-flex justify-content-center align-items-center'>
                        <div>
                            <h4 className='fw-bold fs-1' style={{ color:'rgb(109, 221, 118)'}}>LINKS</h4>
                            <ui className='footerUI fw-semibold' style={{ listStyle: 'none'}}>
                                <li className='my-3'><a href='http://greenrental.herokuapp.com/' className='footerHover'>Rent Car</a></li>
                                <li className='my-3'><a href='http://greenrental.herokuapp.com/about-us.php' className='footerHover'>About Us</a></li>
                                <li className='my-3'><a href='#' className='footerHover'>Career</a></li>
                            </ui>
                        </div>
                    </div>
                    <div className='col-4 d-flex justify-content-center align-items-center'>
                        <div>
                            <h4 className='fw-bold fs-1' style={{ color: 'rgb(109, 221, 118)' }}>CONTACT US</h4>

                            <ui className='fw-semibold' style={{ listStyle: 'none', color: 'white' }}>
                                <li className='my-3 text-center' ><a href='https://www.facebook.com/'><BsFacebook size={30} className='facebookIcon'/></a></li>
                                <li className='my-3 text-center'><a href='https://www.instagram.com/'><BsInstagram size={30} className='instagramIcon' /></a></li>
                                <li className='my-3 text-center'><a href='https://twitter.com/'><BsTwitter size={30} className='twitterIcon' /></a></li>
                            </ui>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text-center py-2" style={{ color: 'rgb(255, 255, 255)' }}>@2022 Copyright: GreenRental Company</div>
        </div>
    )
}
