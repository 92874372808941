
import {Breadcrumbs } from '@mui/material'
import {AiFillHome} from 'react-icons/ai'
import PositionList from './positionList.json'
import { Link,useParams } from 'react-router-dom'
import styles from '../ComponensCSS/Header.module.css'
import { useState } from 'react'
import { BiTime } from 'react-icons/bi'
import Nav from 'react-bootstrap/Nav'




export default function ActiveLastBreadcrumb() {

    /* const [isActive,setIsActive] = useState(false)
    const onClickHandler=()=>{
    setIsActive(!isActive);
    console.log(isActive) */

    let params = useParams();
    let positionData = PositionList.find((index) => {
        return index.id == params.id;
    })


    return (
        <div className='d-flex flex-wrap justify-content-between'>
        {/* <Breadcrumbs className='mt-4 mb-4 ms-3' styles={{MarginBottom:'6rem'}}>
                <Link to={"/"} style={{ color: 'rgb(99, 99, 99)', textDecoration: 'none' }} ><AiFillHome size={'1.5rem'} /></Link>
            <Link to={"/PositionDetail/1"} style={{color:'gray',textDecoration:'none'}} >{PositionList[0].name}</Link>
            <Link to={"/PositionDetail/2"} style={{color:'gray',textDecoration:'none'}} >{PositionList[1].name}</Link>
            <Link to={"/PositionDetail/3"} style={{color:'gray',textDecoration:'none'}} >{PositionList[2].name}</Link>
        </Breadcrumbs> */}

            <Nav className='align-items-center'>
                <Nav.Item>
                    <Nav.Link><Link  to={"/"} style={{ color: 'rgb(99, 99, 99)', textDecoration: 'none' }}><AiFillHome size={'1.5rem'} /></Link></Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link><Link to={"/PositionDetail/1"} style={{color:'gray',textDecoration:'none'}} >{PositionList[0].name}</Link></Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link><Link to={"/PositionDetail/2"} style={{color:'gray',textDecoration:'none'}} >{PositionList[1].name}</Link></Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link><Link to={"/PositionDetail/3"} style={{color:'gray',textDecoration:'none'}} >{PositionList[2].name}</Link></Nav.Link>
                </Nav.Item>
            </Nav>

            <div style={{ color: 'rgb(99, 99, 99)' }} className=' my-4'><BiTime size={'1.5rem'} className="me-3 " />Post Date: {positionData.postTime}</div>
        
        </div>
    );
}
