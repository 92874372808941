import React from 'react'
import {AiOutlineCar} from 'react-icons/ai'
import styles from '../ComponensCSS/PositionList.module.css'
import ecoimg from '../Image/eco.png'
import PositionList from './positionList.json'
import Card from 'react-bootstrap/Card'
import { MdOutlineLocationCity } from 'react-icons/md'
import { BiTime } from 'react-icons/bi'
import moment from 'moment'
import { Link } from 'react-router-dom'



export default function PositionCard() {


    return (
        <>

        <div className={styles.posterWrapper}>
            <div className='text-center text-lg-start'>
                <h1 style={{ fontFamily: 'Cambria', fontWeight: 'bold', lineHeight: '2.5', color:'rgb(243, 132, 41)'}}><q><h3 style={{ fontFamily: 'Cambria', display: 'inline-block', fontWeight: 'bold'}}>We aren’t merely renting vehicle,</h3><br/>
                We’re setting the trend in energy saving <AiOutlineCar /></q></h1>
            </div>
            <img src={ecoimg} width={400}/>
        </div>

        <div style={{backgroundColor: 'rgb(241, 241, 241)'}}>
            <div className={styles.positionTitleWrapper}>
                    <h1 className='text-center py-5 fw-bold' style={{ textShadow: '4px 4px 6px gray' }}>Our Opportunities</h1>
            </div>


            <div className='d-flex justify-content-evenly flex-wrap m5-5'>{PositionList.map(positionList =>
                <div className='d-inline-block my-4' key={positionList.id}>
                    <Card className='p-3 my-5' style={{ width: '20rem' }}>
                        <Card.Img variant="top" src={process.env.PUBLIC_URL+'/img/'+positionList.image[0]} height={'270px'}/>
                        <Card.Body className='text-center mt-3'>
                            <Card.Title className='fw-bold'>{positionList.name}</Card.Title>
                            <Card.Text className='my-4'>
                                <div className='my-2 text-start'><MdOutlineLocationCity size={'1.5rem'} className="me-3" />{positionList.location}</div>
                                <div className='my-2 text-start text-black-50'><BiTime size={'1.5rem'} className="me-3 " />{moment(positionList.postTime).startOf('day').fromNow()}</div>
                            </Card.Text>
                            <Link to={'/PositionDetail/'+positionList.id}><button className={styles.applyBtn} >Apply</button></Link>
                        </Card.Body>
                    </Card>
                </div>
            )}
            </div>
        </div>
        </>
    )
}
