import React from 'react'
import Footer from './components/Footer'
import Header from './components/Header'
import PositionCard from './components/PositionCard'

export default function CareerPage() {
    return (
        <div>
            <Header/>
            <PositionCard />
            <Footer/>
        </div>
    )
}
