import React from 'react'
import { Link } from 'react-router-dom'
import PositionList from './positionList.json'
import { useParams } from 'react-router-dom'
import styles from '../ComponensCSS/Header.module.css'


export default function PositionApply() {

    let params = useParams();
    let positionData = PositionList.find((index) => {
        return index.id == params.id;
    })


    return (
    <>
        <div className='d-flex flex-wrap justify-content-around my-5'>
            <img src={process.env.PUBLIC_URL + '/img/' + positionData.image[1]}  width={420}/>
            <div className='align-items-center my-4 justify-content-conter' style={{lineHeight:'35px'}}>
                <h4 className={styles.applyTitle}>Job Descriptions</h4>
                    <ol>{positionData.duties.map(data => {
                        return (
                            <li key={PositionList.id}>{data}</li>
                        )
                    })}
                </ol>
                <h4 className={styles.applyTitle}>Requirements</h4>
                    <ol>{positionData.requirement.map(data=>{
                    return(
                        <li key={PositionList.id}>{data}</li>
                    )
                })}
                </ol>
            </div>
        </div>
    </>
    )
}
