import React from 'react'
import ApplicationCompleted from './components/ApplicationCompleted'
import Header from './components/Header'
import styles from './ComponensCSS/SuccessA.css'
import Footer from './components/Footer'


export default function SuccessApply() {
    return (
        <div className={styles.bgColor}>

        <Header/>
        <ApplicationCompleted/>
        <Footer/>
        
        </div>
    )
}
