import React from 'react'
import ApplicationForm from './components/ApplicationForm'
import ActiveLastBreadcrumb from './components/Breadcrumbs'
import Footer from './components/Footer'
import Header from './components/Header'
import PositionApply from './components/PositionApply'




export default function PositionDetail() {


    return (
        <div>
            
            <Header />
            <ActiveLastBreadcrumb/>
            <PositionApply />
            <ApplicationForm />
            <Footer/>

        </div>
    )
}
