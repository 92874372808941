import './App.css'
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import CareerPage from './CareerPage'
import PositionDetail from './PositionDetail'
import SuccessApply from './SuccessApply'



function App() {


  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<CareerPage/>} />

        <Route path='/PositionDetail' element={<PositionDetail/>} >
          <Route path=':id' element={<PositionDetail/>} />
        </Route>

        <Route path='/SuccessApply' element={<SuccessApply/>} />
        <Route path='*' element={<h1 style={{textAlign:'center'}}>404 Page not found</h1>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
