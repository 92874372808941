import React from 'react'
import styles from '../ComponensCSS/Header.module.css'
import teamworkImage from '../Image/teamwork.jpg'
import { FaCar } from "react-icons/fa"
import {Link} from 'react-router-dom'
import { HiOutlineMail } from "react-icons/hi"

export default function ApplicationCompleted() {
    return (
        <div>
            <div className='row g-0 row-cols-1 row-cols-md-2 mt-5 p-3 m-2 mb-5' style={{border:'3px solid black'}}>
                <img className='col' src={teamworkImage} style={{borderRadius:'20px'}}/>
                <div className='col text-center p-5'>
                    <h1 className='fw-bolder'><FaCar/> Thank You!</h1>
                    <div className='my-4 fs-5'>We greatly appreciate your willingness to work with us. We will get back to you with 7 business days, so make sure to check your email inbox. <HiOutlineMail/></div>
                    <Link to='/'><button className={styles.backBtn}>View Other Jobs</button></Link>
                    <div className='text-start d-flex align-items-ceten flex-column mt-5'>
                        <p>Phone: +852 21231234</p>
                        <p>whatsapp: 61231234</p>
                        <p>CS.Greenrental@gmail.com</p>
                        <p>地址：九龍觀塘海濱道167-169號</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
