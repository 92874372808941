import React,{useState} from 'react'
import Col from 'react-bootstrap/Col'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import styles from '../ComponensCSS/Header.module.css'
import PositionList from './positionList.json'
import { useParams } from 'react-router-dom'
import Button from 'react-bootstrap/Button'


export default function ApplicationForm() {

    let params = useParams();
    let positionData = PositionList.find((index) => {
        return index.id == params.id;
    })

/*     Get Form Data
    const [details, setDetails] = useState({
        lastName:"",
        phoneNumber:"",
        email:""
    })

    const handleChange = (e) =>{
        //const {name, value} = e.target
        const name = (e.target.name)
        const value = (e.target.value)
        setDetails((prev)=>{
            return {...prev,[name]: value}
        })
    };

    const handleSubmit = (e)=>{
        e.preventDefault();
        console.log(details);
    } */

    return (
    <div className='container mb-5' style={{marginTop:'6rem'}}>

        <h1 className='my-4' style={{color:'rgb(58, 136, 96)',fontWeight:'bold'}}>Application</h1>

        <form /* onSubmit={handleSubmit} */ action='/SuccessApply'> 

            <Form.Control className='fw-bold ms-2 fs-5' plaintext readOnly value={positionData.name} name='position' style={{ width: 'fit-content' }}/>

            <Row className="g-2 mb-4">
                <Col sm={8}>
                    <FloatingLabel controlId="floatingInputGrid" label="First Name">
                            <Form.Control type="text" placeholder="Name" name='firstName'/>
                    </FloatingLabel>
                </Col>
                <Col sm={4}>
                    <FloatingLabel
                        controlId="floatingInputGrid"
                        label="Last Name"
                    >
                            <Form.Control type="text" placeholder="First Name" name='lastName' /* onBlur={handleChange} */ />
                    </FloatingLabel>
                </Col>
            </Row>

            <Row className="g-2 mb-4">
                <Col sm={2}>
                    <FloatingLabel controlId="floatingAreaCode" label="Country Code">
                        <Form.Control type="text" placeholder="Phone" defaultValue={852} onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}}  />
                    </FloatingLabel>
                </Col>
                <Col sm={4}>
                    <FloatingLabel controlId="floatingPhoneNumber" label="Phone Number">
                        <Form.Control type="text" placeholder="Phone" name='phoneNumber' maxLength='8' onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }} /* onBlur={handleChange} */ />
                    </FloatingLabel>
                </Col>
                    <Col sm={6}>
                        <FloatingLabel controlId="floatingInput" label="Email address">
                            <Form.Control type="email" placeholder="name@example.com" name='email' /* onBlur={handleChange} */ />
                        </FloatingLabel>
                    </Col>
            </Row>

            <Row className="g-2">
                <Col sm={12}>
                    <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label className='fw-bold'>Resume </Form.Label>
                        <Form.Control type="file"/>
                    </Form.Group>
                </Col>
            </Row>

            
                <Button className='mt-3' variant="outline-success" type="submit">
                    Submit
                </Button>
            
        </form>
    </div >
    )
}
