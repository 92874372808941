import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import hireLogo from '../Image/hireLogo.png'
import logo from '../Image/logo.png'


export default function Header() {
    return (
        <>
            <div style={{ backgroundColor: 'rgb(175, 248, 185)'}} className='py-5 d-flex align-items-center justify-content-around'>
                <img src={logo} width={200}/>
                <img src={hireLogo} width={200}/>
            </div>
        </>
    )
}

